<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow style="justify-content: space-evenly; align-items: center;">
            <CButton color="primary" @click="getPreview(1)" target="_blank"> 
                Türkçe Önizleme
            </CButton>
            <CButton color="primary" @click="getPreview(33)" target="_blank"> 
                İngilizce Önizleme
            </CButton>
        </CRow>
    </CForm>
</template>

<script>
    // :href="'https://www.kaft.com/company/newsletter/show/'+data.id+'/1'"
    //  :href="'https://www.kaft.com/company/newsletter/show/'+data.id+'/33'"
    export default{
        name: "NewsletterForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed: {
            loading: function(){
                return this.$store.getters.newsletterLoading
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                webDomain: process.env.VUE_APP_SERVERINFO_WEBDOMAIN,
                langs: ['Türkçe', 'İngilizce'],
                openModal: false,
                modalTitle: '',
                modalDesc: '',
                modalProps: {},
                form: ''
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params}
                }
            },
        },
        methods:{
            async openModalEvent( module, form, data, title, desc){
                this.form = form
                this.openModal = !this.openModal
                this.module = module
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            getPreview: async function(lang){
                window.open( this.webDomain + '/company/newsletter/show/' +  this.params.id + '/'+lang, '_blank');
            }
        }
    }
</script>