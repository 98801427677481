<template>
     <CForm class="needs-validation"   >
        <CTabs :activeTab="tabIndex - 1" tabs class="nav-underline nav-underline-primary" >
            <CTab :active="currentIndex == 1" :disabled="currentIndex !== 1">
                
                <template slot="title">
                    Newsletter Bilgileri
                </template>

                <div style="padding: 1rem;" >
                    <NewsletterForm 
                        :params="params" 
                        ref="main"
                    />
                </div>

            </CTab>
            <CTab :active="currentIndex == 2" :disabled="currentIndex !== 2">

                <template slot="title">
                    Newsletter Görselleri (TR)
                </template>

                <div style="padding: 1rem;" v-if="currentIndex == 2">
                    <NewsletterPhotos :params="{templateLang: 'tr', id: newsletterId, photos:[]}" ref="main" />
                </div>

            </CTab>
            <CTab :active="currentIndex == 3" :disabled="currentIndex !== 3">

                <template slot="title">
                    Newsletter Görselleri (EN)
                </template>

                <div style="padding: 1rem;" v-if="currentIndex == 3">
                    <NewsletterPhotos :params="{templateLang: 'en', id: newsletterId, photos:[]}" ref="main" />
                </div>

            </CTab>
            <CTab :active="currentIndex == 4" :disabled="currentIndex !== 4">

                <template slot="title">
                    Önizleme
                </template>

                <div style="padding: 1rem;" v-if="currentIndex == 4">
                    <NewsletterPreview :params="{id: newsletterId}" ref="main" />
                </div>


            </CTab>
        </CTabs>
     </CForm>
</template>

<script>
   
    import NewsletterForm from './newsletterForm';
    import NewsletterPhotos from './newsletterPhotos';
    import NewsletterPreview from './newsletterPreview';

    export default{
        name: "NewsletterStep",
        components: { NewsletterForm, NewsletterPhotos, NewsletterPreview },
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params};
                }
            },
            tabIndex: function(val){
                console.log(val);
                if(this.currentIndex !== val)
                    this.currentIndex = val;
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                tabIndex: 1,
                newsletterId: null,
                currentIndex: 1
            }
        },
    }
</script>
