<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CInput
                    label="Kampanya Adı"
                    placeholder="Reklamlarda görünür örn; 2ndItem50 (Türkçe karakter kullanmadan bitişik yazın)"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.name"
                    required
                    pattern="[A-Za-z0-9]{0,25}" 
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CInput>
            </CCol>
            
        </CRow>
        
        <CRow>
            <CCol>
                <CInput
                    label="Title (TR)"
                    placeholder="Türkçe Mail Konu Başlığı"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.nameTr"
                    required
                >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Title (EN)"
                    placeholder="İngilizce Mail Konu Başlığı"
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.nameEn"
                    required
                >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Background Color"
                    placeholder="#000000 Başında # olacak şekilde newsletter arka plan renk kodunu giriniz"
                    horizontal
                    v-model="data.bgColor"
                    pattern="#[A-Za-z0-9]{3,6}" 
                    required
                >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow class="mb-2">
            <CCol tag="label" sm="3" class="col-form-label">
                <b>Hedef Ülkeler</b>
            </CCol>
            <CCol>

                <CSelect
                    placeholder="Hedef Ülkeler"
                    :options="langs"
                    :value.sync="data.langId"
                />
                
            </CCol>
        </CRow>
        <CRow class="mb-2">
            <CCol tag="label" sm="3" class="col-form-label">
                <b>Target Sub Types</b>
            </CCol>
            <CCol>
                
                <Multiselect 
                    v-model="data.targetSubstypes"
                    :options="comboTargetSubTypes" 
                    :multiple="true" 
                    selected-label="Seçildi"
                    deselect-label="Kaldır"
                    :close-on-select="false" 
                    placeholder="Target Sub Types" 
                    track-by="value" 
                    label="label"
                    @Select="changeTargetSubstypes"
                >
                    <span slot="noResult">Dikkat! Aramanıza uygun kayıt bulunamadı.</span>
                </Multiselect>
            </CCol>
        </CRow>
        <CRow class="mb-2">
            <CCol tag="label" sm="3" class="col-form-label">
                <b>Hedef Kullanıcılar</b>
            </CCol>
            <CCol>

                <CSelect
                    placeholder="Hedef Kullanıcılar"
                    :options="targetUsers"
                    :value.sync="data.onlyInterestedSubs"
                    @change="checkOnlyInterestedSubs(data.onlyInterestedSubs)"
                />
                
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CRow form class="form-group">
                    <CCol col="2" >
                        <b>Aktif</b>
                    </CCol>
                    <CCol col="3" style="margin-left: -15px">
                        <CSwitch
                            class="mr-5"
                            size="lg"
                            color="primary"
                            :checked.sync="data.activeFlag"
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <ConfirmModal
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="modalHandle"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
        />
    </CForm>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import ConfirmModal from '../../components/confirmModal.vue'
    import 'vue-multiselect/dist/vue-multiselect.min.css'
    export default{
        name: "NewsletterForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params};
                }
            },
            data: function(val){
                if(val.targetSubstypes && typeof val.targetSubstypes == "string"){
                    let targetSubstypes = []
                    val.targetSubstypes.split(',').map(t => t !== 'false'  && targetSubstypes.push(this.comboTargetSubTypes[parseInt(t) - 1]))
                    this.data.targetSubstypes = targetSubstypes;
                }
            }
        },
        components:{ 
            Multiselect, ConfirmModal
        },
        mounted: function(){
            if(this.data.targetSubstypes && typeof this.data.targetSubstypes == "string"){
                let targetSubstypes = []
                this.data.targetSubstypes.split(',').map(t => t !== 'false' && targetSubstypes.push(this.comboTargetSubTypes[parseInt(t) - 1]))
                this.data.targetSubstypes = targetSubstypes;
            }

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                comboTargetSubTypes: [
                    { label: 'NewsLetter', value: '1' },
                    /*{ label: 'ProductSale', value: '2'},
                    { label: 'ProductReprint', value: '3' },*/
                    { label: 'Test', value: '4' },
                    { label: 'Promotion', value: '5' }
                ],
                langs: [
                    { label: 'Tümü', value: "" },
                    { label: 'Dili Türkçe Olanlara Gönder', value: 1 },
                    { label: 'Dili İngilizce Olanlara Gönder', value: 2 },
                ],
                targetUsers: [
                    { label: 'Sadece İlgili Kullanıcılar (Son 3 ay içerisinde mail görüntülemiş kişiler)', value: true },
                    { label: 'Tüm Kullanıcılar (Son 2 yıl içerisinde mail görüntülemiş kişiler)', value: false },
                ],
                modalTitle: '',
                module: 'notification',
                form: 'newsletterForm',
                modalProps: {},
                modalDesc: '',
                openModal: false,
            }
        },
        methods: {
            changeTargetSubstypes: function(value){
                this.data.targetSubstypes = value
            },
            openModalEvent(actionType, module, form, data, title, desc){
                if(actionType == undefined) 
                    this.data.onlyInterestedSubs = true
                this.openModal = !this.openModal
                this.actionType = actionType
                this.module = module
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            checkOnlyInterestedSubs(){
                if(this.data.onlyInterestedSubs == false){
                    this.openModalEvent('','notification','confirm', null, 'Dikkat!', 'İlgili gönderim türünü pasif etmek üzeresiniz. Son 2 yıl aktif olan tüm kullanıcılara iletim yapacaktır. Emin misin?')
                }
            },
            modalHandle(){
                this.data.onlyInterestedSubs = false
                this.openModalEvent('')
            }
        }
    }
</script>